.Concept{
    height: 105vh;
    width: 100%;
    margin: 0px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows:  1.6fr 1fr;
    grid-template-areas: "box-1 box-1 box-2 box-2"
                          "box-1 box-1 box-3 box-4"          ;
    .box-1{
        grid-area: box-1;
        background: url("../../../Assets/Concept/bulb.jpg");
        background-repeat: no-repeat;
        background-position: center;
        background-size:cover;
        &__desc{
            position: relative;
            top: 75%;
            left: 8%;
            padding: 20px;
            h1{
                font-size:60px;
                padding: 12px;
            } 
            p{
                font-size: 15px;
                padding: 0px 12px;
            }
        }
    }
    .box-2{
        grid-area: box-2;
        background: url("../../../Assets/Concept/bg2.png");
        background-repeat: no-repeat;
        background-size:cover;
        background-position: top;
    }
    .box-3{
        grid-area: box-3;
        background: url("../../../Assets/Concept/bg3.jpg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;

    }
    .box-4{
        grid-area: box-4;
        background: url("../../../Assets/Concept//bg4.jpg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;

    }
}