.Exhibition{
    min-height: 50vh;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    .box{
        flex:1;
        height: 500px;
        flex-basis: 300px;
        flex-grow: 1;
    }
    .first{
        background: url('../../../Assets/Exhibition/first.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
    .sec{
        background:url("../../../Assets/Exhibition/sec.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
    .third{
        background-color: #1f1f1f;
    }
    .fourth{
        background-color: white;
    }
}