.Navbar{
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    background-color: white;
    align-items: center;
    padding: 0px 25px;
    z-index: 9999;
    .imgContain{
        img{
            height: 90px;
            transition: all 0.3s ease-in-out;
        }
    }
    ul{
        display: flex;
        align-items: center;
        justify-content: center;
        li{
            list-style: none;
            color: rgba(0, 0, 0, 0.548);
            padding: 12px;
        }
    }
}